// EmployeeForm.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { BASE_API_URL } from './config';

const EmployeeForm = () => {
    const [employee, setEmployee] = useState({
        employeename: '',
        phonenumber: '',
        email: '',
        perm_address: '',
        perm_city: '',
        perm_state: '',
        perm_country: '',
        perm_pincode: '',
        dateofjoin: '',
        department: '',
        status:'',
    });

    const [submitted, setSubmitted] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const navigate = useNavigate(); // Initialize navigate

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEmployee({
            ...employee,
            [name]: value,
        });
       
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${BASE_API_URL}storeemployeemaster`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(employee),
            });

            if (response.ok) {
                setSubmitted(true);
                setResponseMessage('Employee data submitted successfully!');
                console.log('Employee Data:', employee);
    
                // Redirect to the employee list page after successful submission
                navigate('/'); // Redirect to the employee list page
            } else {
                setResponseMessage('Failed to submit employee data.');
            }
        } catch (error) {
            console.error('Error submitting data:', error);
            setResponseMessage('An error occurred while submitting data.');
        }
    };

    return (
        <div>
            <h2>Employee Form</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Name:</label>
                    <input
                        type="text"
                        name="employeename"
                        value={employee.employeename}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Phone number:</label>
                    <input
                        type="number"
                        name="phonenumber"
                        value={employee.phonenumber}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Email:</label>
                    <input
                        type="text"
                        name="email"
                        value={employee.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Permananet Address:</label>
                    <textarea
                        name="perm_address"
                        value={employee.perm_address}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>City:</label>
                    <input
                        type="text"
                        name="perm_city"
                        value={employee.perm_city}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>State:</label>
                    <input
                        type="text"
                        name="perm_state"
                        value={employee.perm_state}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Country:</label>
                    <input
                        type="text"
                        name="perm_country"
                        value={employee.perm_country}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Pincode:</label>
                    <input
                        type="number"
                        name="perm_pincode"
                        value={employee.perm_pincode}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Date of join:</label>
                    <input
                        type="date"
                        name="dateofjoin"
                        value={employee.dateofjoin}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Department:</label>
                    <select
                        name="department"
                        value={employee.department}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select Department</option>
                        <option value="HR">HR</option>
                        <option value="Engineering">Engineering</option>
                        <option value="Sales">Sales</option>
                        <option value="Marketing">Marketing</option>
                    </select>
                </div>
                <div>
                    <label>Department:</label>
                    <select
                        name="status"
                        value={employee.status}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select Status</option>
                        <option value="1">Active</option>
                        <option value="0">In Active</option>
                    </select>
                </div>
                <button type="submit">Submit</button>
            </form>

            {responseMessage && <p>{responseMessage}</p>}

            {submitted && (
                <div>
                    <h3>Submitted Employee Data</h3>
                    <p>Name: {employee.employeename}</p>
                    <p>phone: {employee.phonenumber}</p>
                    <p>email: {employee.email}</p>
                    <p>Address: {employee.perm_address}, {employee.perm_state}, {employee.perm_country},{employee.perm_city}- {employee.perm_pincode}</p>
                    <p></p>
                </div>

            )}
        </div>
    );
};

export default EmployeeForm;
