import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_API_URL } from './config';
//import './EmployeeList.css'; // Import the CSS file if using CSS classes

const EmployeeList = () => {
    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate(); // Initialize navigate

    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const response = await fetch(`${BASE_API_URL}showemployeemaster`);
                if (response.ok) {
                    const data = await response.json();
                    setEmployees(data);
                } else {
                    throw new Error('Failed to fetch employee data.');
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchEmployees();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    const handleAddNew = () => {
        navigate('/employeeList'); // Navigate to the EmployeeForm component for adding a new employee
    };

    return (
        <div>
            <h2>Employee List</h2>
            <button onClick={handleAddNew} style={{ marginBottom: '10px' }}>New</button> {/* New button */}
            <table style={{ border: '1px solid black', borderCollapse: 'collapse', width: '100%' }}>
                <thead>
                    <tr>
                        <th style={{ border: '1px solid black', padding: '8px' }}>Name</th>
                        <th style={{ border: '1px solid black', padding: '8px' }}>Phone Number</th>
                        <th style={{ border: '1px solid black', padding: '8px' }}>Email</th>
                        <th style={{ border: '1px solid black', padding: '8px' }}>Address</th>
                        <th style={{ border: '1px solid black', padding: '8px' }}>City</th>
                        <th style={{ border: '1px solid black', padding: '8px' }}>State</th>
                        <th style={{ border: '1px solid black', padding: '8px' }}>Country</th>
                        <th style={{ border: '1px solid black', padding: '8px' }}>Pincode</th>
                        <th style={{ border: '1px solid black', padding: '8px' }}>Date of Join</th>
                        <th style={{ border: '1px solid black', padding: '8px' }}>Department</th>
                    </tr>
                </thead>
                <tbody>
                    {employees.map((employee) => (
                        <tr key={employee.id}>
                            <td style={{ border: '1px solid black', padding: '8px' }}>{employee.employeename}</td>
                            <td style={{ border: '1px solid black', padding: '8px' }}>{employee.phonenumber}</td>
                            <td style={{ border: '1px solid black', padding: '8px' }}>{employee.email}</td>
                            <td style={{ border: '1px solid black', padding: '8px' }}>{employee.perm_address}</td>
                            <td style={{ border: '1px solid black', padding: '8px' }}>{employee.perm_city}</td>
                            <td style={{ border: '1px solid black', padding: '8px' }}>{employee.perm_state}</td>
                            <td style={{ border: '1px solid black', padding: '8px' }}>{employee.perm_country}</td>
                            <td style={{ border: '1px solid black', padding: '8px' }}>{employee.perm_pincode}</td>
                            <td style={{ border: '1px solid black', padding: '8px' }}>{employee.dateofjoin}</td>
                            <td style={{ border: '1px solid black', padding: '8px' }}>{employee.department}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default EmployeeList;
